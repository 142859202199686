
import * as _action from 'action-types';
import { checkIfUserDidAdvanceSearch, checkUserPermissionOnPage, decrypt, deepCopy, downloadExcelFile, downloadFileByBase64, encrypt, exportFileName, exportWordFile, extractValueFromUrl, formatDateToMMDDYYYY, getCurrentlyShowingItemsInGrid, getMessage, getUrlForExportData, loadScriptTag, replaceUrlParam, scrollToTopOfPage, updateGridProps, validateExportableFields } from 'helpers/util-common';
import { AppGridIds } from 'models/common.models';
import { MembershipTypes } from './members.model';

let self = {};
export const setClassInstance = (instance) => {
    self = instance;
    loadScript();
    setUserAccess(self.props.userPermission);
}

export const handleComponentWillUpdate = (nextProps) => {
    const { currentSearchType, currentModuleTitle } = nextProps;

    if (nextProps.location.search !== self.props.location.search && currentSearchType === _action.SEARCH_MEMBERS) {
        const { pageSize, sortedColumn, sortOrder } = self.state;
        const searchText = nextProps.location.search.length > 0 ? nextProps.location.search.substring(1) : '';
        const selectedMemberType = getSelectedMemberTypesFromUrl(searchText);
        const selectedStatus = getStatusFromUrl(searchText);
        const selectedCountry = getCountryFromUrl(searchText);
        const selectedPaidStatus = getPaidStatusFromUrl(searchText);
        self.setState({
            searchText,
            activePageNumber: 1,
            selectedMemberType: Number(selectedMemberType),
            selectedCountry,
            selectedStatus: Number(selectedStatus),
            selectedPaidStatus,
            isTableHeaderReset: false,
            isFilterChanged: false
        });

        loadData(1, pageSize, searchText, sortedColumn, sortOrder, selectedMemberType, selectedStatus, selectedCountry, selectedPaidStatus);
    }

    // Recheck the user permissions when user click on Back button of window tab
    if (currentModuleTitle !== self.props.currentModuleTitle) {
        setUserAccess(nextProps.userPermission);
    }
}

export const loadData = (pageNumber, pageSize, searchText, sortedColumn, sortOrder, selectedMemberType, selectedStatus, selectedCountry, selectedPaidStatus, showPageLoader = false, firstLoad = false) => {
    const { memberGridPreferences } = self.props;
    const query = getQuery(pageNumber, pageSize, searchText, sortedColumn, sortOrder, selectedMemberType, selectedStatus, selectedCountry, selectedPaidStatus);

    if (!showPageLoader) {
        self.setState({ showLoader: true });
    }

    if (self.props.membershipTypeList.length > 0 && memberGridPreferences.length > 0) {
        self.props.getMemberList(query, (memberTableResult) => {
            self.setState({
                tableHeaderConfig: memberGridPreferences
            })
            handleMemberListDataResponse(memberTableResult, memberGridPreferences, pageSize, pageNumber);
        }, showPageLoader, self);
    }
    else {
        self.props.getInitialMemberListData(query, (memberTableResult, memberHeaderResult) => {
            // Update the tableHeader config into state.
            self.setState({
                tableHeaderConfig: memberHeaderResult
            })
            handleMemberListDataResponse(memberTableResult, memberHeaderResult, pageSize, pageNumber);
        }, showPageLoader)
    }
}

export const getSelectedMemberTypesFromUrl = (searchText) => {
    return extractValueFromUrl(searchText, 'membershiptypeid');
}

export const getStatusFromUrl = (searchText) => {
    let status = extractValueFromUrl(searchText, 'status');
    return status !== null ? status : (searchText.length === 0 ? 17 : 0);
}

export const getCountryFromUrl = (searchText) => {
    let country = extractValueFromUrl(searchText, 'country');
    return country !== null ? decrypt(country) : 'All';
}

export const getPaidStatusFromUrl = (searchText) => {
    let paidStatus = extractValueFromUrl(searchText, 'paidstatus');
    return paidStatus !== null ? paidStatus : 'All';
}

// Private functions
const getQuery = (pageNumber, pageSize, searchText, sortedColumn, sortOrder, selectedMemberType, selectedStatus, selectedCountry, selectedPaidStatus) => {
    let query = `gridId=${AppGridIds.MemberGrid}&pageNumber=${pageNumber}&pageSize=${pageSize}`;

    if (searchText && searchText.length > 0) {
        query = `${query}&${searchText}`;
    }
    if (sortedColumn && sortedColumn.length > 0) {
        query = `${query}&sortedColumn=${sortedColumn}`;
    }
    if (sortOrder === 1 || sortOrder === 2) {
        const order = sortOrder === 1 ? 'asc' : 'desc';
        query = `${query}&sortOrder=${order}`;
    }

    if (selectedMemberType !== null && selectedMemberType >= 0) {
        let memberTypeId = extractValueFromUrl(query, "membershiptypeid");

        if (memberTypeId === null) {
            query = `${query}&membershiptypeid=${selectedMemberType}`;
        } else if (memberTypeId !== null && Number(memberTypeId) !== Number(selectedMemberType)) {
            query = replaceUrlParam(query, "membershiptypeid", selectedMemberType);
        }
    }

    if (selectedCountry && selectedCountry.length > 0) {
        let country = extractValueFromUrl(query, "country");

        if (country === null) {
            query = `${query}&country=${encrypt(selectedCountry)}`;
        } else if (country !== null && decrypt(country) !== selectedCountry) {
            query = replaceUrlParam(query, "country", encrypt(selectedCountry));
        }
    }

    if (selectedStatus !== null && selectedStatus >= 0) {
        let status = extractValueFromUrl(query, "status");
        // Check Status key in URL
        if (status === null) {
            query = `${query}&status=${selectedStatus}`;
        } else if (status !== null && Number(status) !== Number(selectedStatus)) {
            query = replaceUrlParam(query, "status", selectedStatus);
        }
    }

    if (selectedPaidStatus !== null && selectedPaidStatus.length >= 0) {
        let paidStatus = extractValueFromUrl(query, "paidstatus");

        if (paidStatus === null) {
            query = `${query}&paidstatus=${encrypt(selectedPaidStatus)}`;
        } else if (paidStatus !== null && paidStatus !== selectedPaidStatus) {
            query = replaceUrlParam(query, "paidstatus", encrypt(selectedPaidStatus));
        }
    }

    return query;
}


const updateCurrentlyShowingItems = (totalItems, pageSize, pageNumber = 1) => {
    let totalPages = Math.ceil(totalItems / pageSize);
    const currentlyShowingItems = getCurrentlyShowingItemsInGrid(pageNumber, pageSize, totalItems);

    self.setState({ currentlyShowingItems, totalPages });
}

const handleMemberListDataResponse = (memberTableResult, memberTableHeader, pageSize, pageNumber) => {
    let members = [];
    let count = 0;
    if (memberTableResult && memberTableResult.Count && memberTableResult.Members) {
        members = memberTableResult.Members;
        count = memberTableResult.Count
    }

    if (count > pageSize) {
        self.state.showPager = true;
    }

    let headerInfo = memberTableHeader && memberTableHeader.length > 0 ? memberTableHeader : self.state.tableHeaderConfig;
    setAllGridPreference(members, headerInfo, count);
    updateCurrentlyShowingItems(count, pageSize, pageNumber);
    // Whenever data is refreshed, Move the scroll to top.
    scrollToTopOfPage();
}

export const setGridPreferenceParams = (params) => {
    const { gridProp } = self.state;
    let gridConfigParams = { "Columns": params };
    gridProp.requestSaveParams = gridConfigParams;
    self.setState({
        gridProp
    })
}

export const resetGridTableData = () => {
    const { activePageNumber, pageSize, searchText, sortedColumn, sortOrder, selectedMemberType, selectedCountry, selectedStatus, selectedPaidStatus } = self.state;
    const query = getQuery(activePageNumber, pageSize, searchText, sortedColumn, sortOrder, selectedMemberType, selectedStatus, selectedCountry, selectedPaidStatus);
    self.props.getInitialMemberListData(query, (memberTableResult, memberHeaderResult) => {
        // Update the tableHeader config into state
        self.setState({
            tableHeaderConfig: memberHeaderResult
        })
        handleMemberListDataResponse(memberTableResult, memberHeaderResult, pageSize, activePageNumber);
    });
}

export const saveGridPreferenceClickAction = () => {
    const { gridProp } = self.state;
    self.setState({ isShowGridMenu: false });
    self.props.updateGridPreference(AppGridIds.MemberGrid, gridProp.requestSaveParams, (result) => {
        if (result) {
            self.setState({ isShowGridMenu: true }, () => resetGridTableData());
        } else {
            self.setState({ isShowGridMenu: false });
        }
    });
}

export const excelExportableFieldsCallback = (fields) => {
    let exportFields = {};
    let column = [];
    let columnsKeys = [];

    const { gridProp } = self.state;
    if (fields !== null) {
        for (let keys in fields) {
            if (fields[keys]) {
                columnsKeys.push(keys);
                exportFields[keys] = fields[keys];
                column.push({ title: gridProp.displayName[keys], fieldsKey: keys })
            }
        }
        gridProp.excelExportedColumn = column;
        self.setState({
            gridProp
        }, () => setGridPreferenceParams(validateExportableFields(self.state.tableHeaderConfig, columnsKeys)))
    };
}

export const setAllGridPreference = (memberTableResult, gridHeader, count) => {
    const { gridProp, isTableHeaderReset, isFilterChanged } = self.state;
    let columnKeys = [];
    if (isTableHeaderReset) { // Worked only value of isTableHeaderReset = true;
        // Set empty object for set all the grid table values
        let tableConfigProps = {
            attributes: {}, displayName: {}, expandables: [], sortables: {}, omitOnMenu: [], excelExportedColumn: [], emptyMessage: checkIfUserDidAdvanceSearch(self.props.location.search) || isFilterChanged ? getMessage(self.props.messageCodes, '9013.text') : getMessage(self.props.messageCodes, '9011.text'), records: memberTableResult, excelExportableFieldsCallback: excelExportableFieldsCallback, requestSaveParams: {}
        }

        updateGridProps(gridHeader, tableConfigProps, columnKeys);

        self.setState({
            showLoader: false,
            totalItems: count,
            isDataUpdated: true,
            gridProp: tableConfigProps
        }, () => setGridPreferenceParams(columnKeys));
    } else {
        // Update only grid records
        updateGridProps(gridHeader, gridProp, columnKeys);
        gridProp.records = memberTableResult;
        gridProp.emptyMessage = checkIfUserDidAdvanceSearch(self.props.location.search) || isFilterChanged ? getMessage(self.props.messageCodes, '9013.text') : getMessage(self.props.messageCodes, '9011.text');
        self.setState({
            showLoader: false,
            totalItems: count,
            isDataUpdated: true,
            gridProp
        })
    }
}

// Http Request for Exported Data
export const getMemberExportedExcel = () => {
    callExportAPI(getExportedExcelResponse, true);
}

// Exported Excel Response in Callback : -
export const getExportedExcelResponse = (response) => {
    if (response !== null && response.length > 0) {
        excelExportSetup(response);
    } else {
        self.setState({
            isExcelEmpty: true,
            showLoader: false
        })
    }

}

export const excelExportSetup = (result) => {
    const { gridProp } = self.state;
    const fileName = exportFileName('Member Records');

    downloadExcelFile(gridProp, (result && result.length ? result : []), 'Members', fileName, () => {
        self.setState({
            showLoader: false
        });
    });
}

export const filterMembershipTypes = () => {
    let membershipTypeList = deepCopy(self.props.membershipTypeList);
    membershipTypeList = membershipTypeList.filter(type => {
        return type.key !== MembershipTypes.Organizational
    })
    return membershipTypeList;
}

export const fileName = (name) => {
    return exportFileName(name)
}

export const emptyExcelCancelHandler = () => {
    self.setState({
        isExcelEmpty: false
    });
}

export const emptyExcelConfirmHandler = () => {
    self.setState({
        isExcelEmpty: false
    }, () => excelExportSetup([])); // Exported Empty File
}


// Start work on Word  export part :-
export const emptyWordCancelHandler = () => {
    self.setState({
        isWordEmpty: false
    });
}

export const emptyWordConfirmHandler = () => {
    self.setState({
        isWordEmpty: false
    }, () => getWordDocxPageResponse([])); // Exported Empty File
}

export const getMemberExportedWord = () => {
    callExportAPI(getExportedWordResponse, false)
}

// Exported Word Response in Callback : -
export const getExportedWordResponse = (response) => {
    if (response !== null && response.length > 0) {
        const fileName = exportFileName('Member Records') + '.docx';
        downloadFileByBase64(response, fileName);
        self.setState({ showLoader: false }) // Disable loader while file downloaded
    } else {
        self.setState({
            isWordEmpty: true,
            showLoader: false
        })
    }
}

export const getWordDocxPageResponse = (response) => {
    const { gridProp } = self.state;
    let data = [];
    if (response && response.length > 0) {
        response.map((item) => {
            data.push(item);
        })
    }
    let docXConfig = new window.docXConfiguration(gridProp.excelExportedColumn, exportFileName('Member Records_'), formatDateToMMDDYYYY(), "Member Records", [], 'member');

    exportWordFile(docXConfig, data);
    self.setState({ showLoader: false }) // Disable loader while file downloaded
}

export const loadScript = () => {
    loadScriptTag(['/docx/docx.js', process.env.PUBLIC_URL + '/docx/file-save.js', process.env.PUBLIC_URL + '/docx/docx-service.js'], 'head');
}

const setUserAccess = (userPermission) => {
    const { PAGE_TYPE, USER_PRIVILEGE } = self.props;
    self.setState({
        hasExportAccess: checkUserPermissionOnPage(userPermission, PAGE_TYPE.MEMBER, USER_PRIVILEGE.EXPORT)
    })
}

const callExportAPI = (callback, isExcel) => {
    const { gridProp, totalItems } = self.state;
    const _gridProp = Object.create(gridProp)
    let isWord = false;
    if (isExcel) {
        const index = _gridProp.excelExportedColumn.findIndex((i) => i.title === 'Name');

        if (index !== -1) {
            _gridProp.excelExportedColumn.splice(index + 1, 0, { title: 'First Name', fieldsKey: 'MemberDetail_FirstName' },
                { title: 'Middle Initial', fieldsKey: 'MemberDetail_MiddleName' },
                { title: 'Last Name', fieldsKey: 'MemberDetail_LastName' })
        }
    } else {
        isWord = true
    }

    const excelColumns = _gridProp.excelExportedColumn && _gridProp.excelExportedColumn.length > 0 ?
        _gridProp.excelExportedColumn.map(i => i.fieldsKey) : [];
    const urlString = getUrlForExportData(totalItems, isWord);
    if (isWord) {
        self.props.getMemberExportedData(callback, urlString, excelColumns, isWord);
    } else {
        self.props.getMemberExportedData(callback, urlString, excelColumns);
    }
    self.setState({
        showLoader: true
    });
}
