import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import 'semantic-ui-css/semantic.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import { Loader } from 'semantic-ui-react';
import AppRoutes from './app.routes';
import './assets/themes/main.css';
import CacheBuster from './CacheBuster';
import { currentLocationStorage } from './common.actions';
import { azureAdFetchCode, clearAzureAdAuthCodeAction, extractValueFromUrl, isReactAppAuthorized, isTokenExistInCookies, routeNavigationUrl, cacheManager } from './helpers/util-common';
import CustomAlertModal from './shared-components/CustomAlertModal';
import CustomConfirmPopup from './shared-components/CustomConfirmPopup';
import ErrorModalBox from './shared-components/ErrorModalBox';
import CustomLoader from './shared-components/Loader';
import PrivilegeNotFound from './shared-components/PrivilegeNotFound';
import SafariAutofillMessage from './shared-components/SafariAutofillMessage';
import MessageDismissible from './shared-components/ToastMessage';
import {DMSIANavigation} from './models/navigation.models'
class App extends Component {

	state = {
		defaultNavigationUrl: null,
		isLoginByAzureAD: process.env.REACT_APP_LOGIN_BY_AZURE_AD == "true",
		isUserHavePrivileges: false,
        hasServerSession:false
	}

	componentDidMount() {
		this.loadAppWithDefaultUrl();
	}

	loadAppWithDefaultUrl = () => {
		// Get Nav Default Url
		this.setState({ defaultNavigationUrl: this.validateDefaultUrl() },
			() =>{
          this.props.checkSessionInfo((response) => {
            let keyStatus=false;
          if (response.success) {
            
            if (!response.data.hasIAAppKey) {
                cacheManager.removeItem('isReactAppAuthorized');
            }else{
              keyStatus=true;
            }
          }
          this.setState({hasServerSession:keyStatus})
          this.validateAzureCodeOnUrl();
        });
      });
	}

	// Validate Default Url => Login/Dashboard
	validateDefaultUrl = () => {
		const { PAGE_TYPE, USER_PRIVILEGE } = this.props;
		const { isLoginByAzureAD } = this.state;
		if (isReactAppAuthorized()) {
			return routeNavigationUrl(PAGE_TYPE.MEMBER, USER_PRIVILEGE.VIEW);
		} else if (isLoginByAzureAD && !isReactAppAuthorized()) {
			return null;
		}
		return '/login';
	}

	loadPreAPI = () => {
		this.props.getCountryAndStateList();
	}
	setDefaultURL(url, callback){
		this.setState({ defaultNavigationUrl: url },
			() => callback());
	}
	DMSTOIAHandler(pathName) {
		const { PAGE_TYPE, USER_PRIVILEGE } = this.props;
		if (DMSIANavigation.MEMBER_MGMT.includes(pathName)) {
			this.setDefaultURL(routeNavigationUrl(PAGE_TYPE.MEMBER, USER_PRIVILEGE.VIEW), ()=>{
				this.props.history.go(routeNavigationUrl(PAGE_TYPE.MEMBER, USER_PRIVILEGE.VIEW))
			})
		} else if (DMSIANavigation.MEMBER_ORG.includes(pathName)) {
			this.setDefaultURL(routeNavigationUrl(PAGE_TYPE.ORGANIZATION, USER_PRIVILEGE.VIEW), ()=>{
				this.props.history.go(routeNavigationUrl(PAGE_TYPE.ORGANIZATION, USER_PRIVILEGE.VIEW))
			})
		} else if (DMSIANavigation.MEMBER_FEE_GROUP.includes(pathName)) {
			this.setDefaultURL(routeNavigationUrl(PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.VIEW), ()=>{
				this.props.history.go(routeNavigationUrl(PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.VIEW))
			});
		} else if (DMSIANavigation.MEMBER_COMMITTEE.includes(pathName)) {
			this.setDefaultURL(routeNavigationUrl(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.VIEW), () => {
			this.props.history.go(routeNavigationUrl(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.VIEW))
			})
		} else if (DMSIANavigation.RENEWAL_TASK.includes(pathName)) {
			this.setDefaultURL(routeNavigationUrl(PAGE_TYPE.TASK, USER_PRIVILEGE.VIEW), ()=>{
				this.props.history.go(routeNavigationUrl(PAGE_TYPE.TASK, USER_PRIVILEGE.VIEW))
			});
		} else if (DMSIANavigation.WORK_ITEM_ADMIN.includes(pathName)) {
			this.setDefaultURL(routeNavigationUrl(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL, USER_PRIVILEGE.VIEW),()=>{
				this.props.history.go(routeNavigationUrl(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL, USER_PRIVILEGE.VIEW))
			});
		} else if (DMSIANavigation.BALLOT_ADMIN.includes(pathName)) {
			this.setDefaultURL(routeNavigationUrl(PAGE_TYPE.BALLOT_ADMIN, USER_PRIVILEGE.VIEW), ()=>{
				this.props.history.go(routeNavigationUrl(PAGE_TYPE.BALLOT_ADMIN, USER_PRIVILEGE.VIEW))
			});
		} else if (DMSIANavigation.ADMIN_ROLE.includes(pathName)) {
			this.setDefaultURL(routeNavigationUrl(PAGE_TYPE.ROLE, USER_PRIVILEGE.VIEW), ()=>{
				this.props.history.go(routeNavigationUrl(PAGE_TYPE.ROLE, USER_PRIVILEGE.VIEW));
			});
		} else if (DMSIANavigation.ADMIN_MEMBER_RENEWAL.includes(pathName)) {
			this.setDefaultURL(routeNavigationUrl(PAGE_TYPE.MEMBERSHIP_RENEWAL, USER_PRIVILEGE.VIEW), ()=>{
				this.props.history.go(routeNavigationUrl(PAGE_TYPE.MEMBERSHIP_RENEWAL, USER_PRIVILEGE.VIEW));
			});
		}
		else if (DMSIANavigation.ADMIN_MANUAL_ORDER.includes(pathName)) {
			this.setDefaultURL(routeNavigationUrl(PAGE_TYPE.ADD_MEMBER, USER_PRIVILEGE.VIEW), ()=> {
				this.props.history.go(routeNavigationUrl(PAGE_TYPE.ADD_MEMBER, USER_PRIVILEGE.VIEW));
			});
		} else if (DMSIANavigation.ADMIN_MEMBER_EXEP.includes(pathName)) {
			this.setDefaultURL(routeNavigationUrl(PAGE_TYPE.MEMBER_EXCEPTION, USER_PRIVILEGE.VIEW), ()=>{
				this.props.history.go(routeNavigationUrl(PAGE_TYPE.MEMBER_EXCEPTION, USER_PRIVILEGE.VIEW));
			});
		} else if (DMSIANavigation.ADMIN_BOS_VOLUME_LOCATION.includes(pathName)) {
			this.setDefaultURL(routeNavigationUrl(PAGE_TYPE.BOS_VOLUME_LOCATION, USER_PRIVILEGE.VIEW), ()=>{
				this.props.history.go(routeNavigationUrl(PAGE_TYPE.BOS_VOLUME_LOCATION, USER_PRIVILEGE.VIEW))
			});
		} else if (DMSIANavigation.MEMBER_REPORT.includes(pathName)) {
			this.setDefaultURL(routeNavigationUrl(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.VIEW), ()=>{
				this.props.history.go(routeNavigationUrl(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.VIEW))
			});
		} else if (DMSIANavigation.RENEWAL_REPORT.includes(pathName)) {
			this.setDefaultURL(routeNavigationUrl(PAGE_TYPE.RENEWAL_REPORT, USER_PRIVILEGE.VIEW), ()=>{
				this.props.history.go(routeNavigationUrl(PAGE_TYPE.RENEWAL_REPORT, USER_PRIVILEGE.VIEW))
			});
		} else if (DMSIANavigation.MEETING_REPORT.includes(pathName)) {
			this.setDefaultURL(routeNavigationUrl(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.VIEW), ()=>{
				this.props.history.go(routeNavigationUrl(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.VIEW))
			});
		} else {
			this.props.history.go(routeNavigationUrl(PAGE_TYPE.MEMBER, USER_PRIVILEGE.VIEW));
		}
	}

	// Validate Azure AD request for AD-TOKEN
	validateAzureCodeOnUrl = () => {
		const { PAGE_TYPE, USER_PRIVILEGE } = this.props;
		const { isLoginByAzureAD } = this.state;
		const locationObject = currentLocationStorage();
		let isLoginWithAD = isLoginByAzureAD;
		if (isLoginWithAD && !isReactAppAuthorized()) {
			if (isTokenExistInCookies()) {
				// RNE Application type Id, For get Redis Key.
				let applicationTypeId = 1;
				this.props.authByCookiesToken({ appId: applicationTypeId }, (isSuccess, code) => {
					if (isSuccess) {
            this.setState({hasServerSession:true});
						this.loadPreAPI();
						if (locationObject.isLocation) {
							this.props.history.push(locationObject.location)
						} else {
							let pathName=window.location.pathname;
							if(pathName){
								this.DMSTOIAHandler(pathName)
							}else{
								this.props.history.push(routeNavigationUrl(PAGE_TYPE.MEMBER, USER_PRIVILEGE.VIEW))

							}
						}
					} else if (!isSuccess && code === '1013') {
						// Call here for No Privilege Found
						this.setState({
							isUserHavePrivileges: true
						})
					} else {
						// Else clear session.
						window.location.assign(clearAzureAdAuthCodeAction());
					}
				})
			} else {
				let code = extractValueFromUrl(window.location.href, "code");
				let sessionState = extractValueFromUrl(window.location.href, "session_state");
				let authState = extractValueFromUrl(window.location.href, "state");
				if (code && sessionState && authState) {
					// TODO : Azure Init Call for create browser session.
					this.props.initiateAzureRequest(code, sessionState, authState, isSuccessAzureInitRequest => {
						if (isSuccessAzureInitRequest) {
							// TODO : Auth Code Login Call while get success azure init call.
							this.props.authenticateByAzureAD((isSuccessAzureAuthRequest, resCode) => {
								if (isSuccessAzureAuthRequest) {
                  this.setState({hasServerSession:true});
									this.loadPreAPI();
									if (locationObject.isLocation) {
										this.props.history.push(locationObject.location)
									} else {
										this.props.history.push(routeNavigationUrl(PAGE_TYPE.MEMBER, USER_PRIVILEGE.VIEW))
									}
								} else if (!isSuccessAzureAuthRequest && resCode === '1013') {
									// Call here for No Privilege Found
									this.setState({
										isUserHavePrivileges: true
									})
								} else {
									// Else clear session.
									window.location.assign(clearAzureAdAuthCodeAction());
								}
							});
						} else {
							// Call here for No Privilege Found
							this.setState({
								isUserHavePrivileges: true
							})
						}
					});
				} // Redirect for get token from Azure AD
				else {
					window.location.assign(azureAdFetchCode());
				}
			}
		}

	}

	render() {

		const { loading, showToastMessage, showErrorPopup, isShowConfirmationPopup, content, errorMessages, confirmationMessage, showAlertPopup, alertMessage, miniLoader, isSafariAutoFillInfoVisible, setSafariAutoFillInfo } = this.props;
		const { defaultNavigationUrl, isLoginByAzureAD, isUserHavePrivileges, hasServerSession } = this.state;
		// Validate User is Authorized or Not !!
		let isUserAuthorized = isReactAppAuthorized()
		return (
			<div className="appWrapper">

				{loading && <CustomLoader />}
				{miniLoader && <Loader size='small' className="small-loader">Loading</Loader>}
				{isSafariAutoFillInfoVisible && <SafariAutofillMessage setSafariAutoFillInfoHandler={setSafariAutoFillInfo} isSafariAutoFillInfoVisible={isSafariAutoFillInfoVisible} />}
				{isUserHavePrivileges ?
					<PrivilegeNotFound />
					:
				hasServerSession && <AppRoutes {...this.props} defaultUrl={defaultNavigationUrl} isUserAuthorized={isUserAuthorized} isLoginByAzureAD={isLoginByAzureAD} />
				}
				{showToastMessage && (
					<MessageDismissible content={content} />
				)}
				{showErrorPopup && (
					<ErrorModalBox content={errorMessages} />
				)}
				{isShowConfirmationPopup &&
					<CustomConfirmPopup content={confirmationMessage} />
				}
				{showAlertPopup && (
					<CustomAlertModal content={alertMessage} />
				)}
				<CacheBuster>
					{({ loading, isLatestVersion, refreshCacheAndReload }) => {
						if (loading) return null;
						if (!loading && !isLatestVersion) {
							refreshCacheAndReload();
						}
						return (
							<div>
							</div>
						);

					}}
				</CacheBuster>
			</div>
		);
	}
}
export default App;
